.EventList {
    display: grid;
    grid-template-columns: auto 1fr auto;

    .name {
        grid-column: 1;
        padding-right: 15px;
    }

    .code {
        grid-column: 2;
    }

    .edit {
        grid-column: 3;
    }
}
