.App {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  .flex-fill {
    flex: 1 1 auto;
  }

  .p-button {
    background-color: #d57022;
    border: 1px solid #784015;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    flex: 1 1 auto;

    background-color: gray;

    $border: 1px solid black;
    border-left: $border;
    border-right: $border;

    #app-top {
      flex: 0 0 none;
    }

    #app-content {
      flex: 1 1 auto;
      overflow-y: auto;

      .p-card-body {
        padding: 1em 5px;
      }
    }

    #app-bottom {
      flex: 0 0 none;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    background-color: black;

    img#footer-logo {
      height: 30px;
      margin: 4px 0 1px 10px;
    }
  }
}

body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a {
  background-color: #d57022;
  border: 1px solid #784015;
}
