.CreateEvent {
    .control-stack {
        display: grid;
        grid-template-columns: 1 auto;

        label {
            grid-column: 1;
            padding-right: 25px;
        }

        input {
            grid-column: 2;
        }
    }
}
