.MessageList {
    padding: 10px;
    .message-listing {
        margin-bottom: 15px;
    }
    .message-list {
        display: flex;
        flex-direction: column;
    }
}
