.EnterEvent {
    .event-field {
        display: flex;
        flex-direction: row;

        label {
            display: block;
            margin-right: 20px;
            align-self: center;
        }
    }

    #error-message {
        color: red;
        font-weight: bolder;
    }
}
