.AppSettings {
    .settings-column {
        display: grid;
        grid-template-columns: auto 1;
        padding: 10px;

        label {
            grid-column: 1;
        }

        > div {
            grid-column: 2;
        }
    }

    .inline-grid {
        display: flex;
        flex-direction: row;

        > div {
            align-self: center;
        }
    }
}
