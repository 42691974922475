.LogViewer {
    .log-entry {
        display: flex;
        flex-direction: row;

        > * {
            margin-left: 15px;
        }
    }

    .log-entries {
        display: grid;
        grid-template-columns: auto 1;

        .border-row {
            border-bottom: 1px solid black;
        }

        .object-property {
            display: flex;
            flex-direction: row;

            > * {
                width: 100%;
            }
            .property {
                font-weight: bolder;
            }

            .value {
            }
        }

        .header-row {
            background-color: #decfb5;
            font-weight: bolder;
        }
    }
}
