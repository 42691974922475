.AppToolbar {
    .p-toolbar {
        background-color: black;
        border: none;
        border-radius: 0;
    }

    .p-button {
        margin-left: 1px;
    }

    .no-text-button {
        height: 33px;
        .p-button-text {
            display: none;
        }
    }

    img.dots {
        height: 18px;
        margin-top: 4px;
    }

    img#logo {
        height: 30px;
        margin-right: 10px;
        margin-top: 3px;
    }

    .p-toolbar-group-left {
        display: flex;
    }
}
